import { FC, useState } from 'react'
import Image from '../Image/Image'
import { Accordion } from 'component-library'
import './ProcessSection.scss'
import MarkdownText from '../MarkdownText/MarkdownText'
import { slugifyString } from 'shared-utilities'

interface IProcessSectionProps {
  id?: string
  title: string
  steps: {
    title: string
    icon: string
    description: string
  }[]
  addClass?: string
  gaEventCategory?: string
}

const ProcessSection: FC<IProcessSectionProps> = ({
  id,
  title,
  steps,
  addClass = '',
  gaEventCategory,
}) => {
  const [accordionItem, setAccordionItem] = useState(steps[0])

  return (
    <section
      className={`ProcessSection ${addClass}`}
      id={id || slugifyString(title) || ''}
    >
      <h2 className="ProcessSection__header">{title}</h2>

      <div className="ProcessSection__accordion">
        <div className="ProcessAccordion__items">
          {steps.map((step, index) => (
            <button
              className={`ProcessAccordion__item ${
                accordionItem === step
                  ? 'AccordionItem__expanded'
                  : 'AccordionItem__transition'
              }`}
              key={`${step.title}-${index}`}
              aria-label={`Read about ${step.title}`}
              aria-expanded={accordionItem === step}
              onClick={() => setAccordionItem(step)}
            >
              <div className="flex text-start items-center">
                {step.icon && (
                  <Image
                    src={
                      step.icon.startsWith('https://')
                        ? step.icon
                        : `/images/${step.icon}`
                    }
                    alt=""
                  />
                )}
                {step.title}
              </div>
              <i className="bx bx-chevron-right"></i>
              {step !== steps[0] && <div className="cornerTop"></div>}
              {step !== steps[steps.length - 1] && (
                <div className="cornerBottom"></div>
              )}
            </button>
          ))}
        </div>
        <div
          className={`ProcessAccordion__description ${
            accordionItem === steps[0]
              ? 'rounded-tl-none'
              : accordionItem === steps[steps.length - 1]
              ? 'rounded-bl-none'
              : ''
          }`}
        >
          <MarkdownText
            className="ProcessAccordion__text"
            text={accordionItem.description}
            gaEventCategory={gaEventCategory}
          />
        </div>
      </div>
      {steps.map((step, index) => (
        <Accordion
          key={`${step.title}-${index}`}
          itemTitle={
            <>
              <Image
                src={
                  step.icon.startsWith('https://')
                    ? step.icon
                    : `/images/${step.icon}`
                }
                alt=""
              />
              <h2 className="text-lg pr-4">{step.title}</h2>
            </>
          }
          itemContent={<MarkdownText text={step.description} />}
          isExpandedDefault={index === 0}
        />
      ))}
    </section>
  )
}

export default ProcessSection
